<ng-container>
  <ul class="broadcast" *ngIf="lists?.count > 0">
    <li class="broadcast-item" [ngClass]="{'hidden': list.users == 0}" *ngFor="let list of lists?.data" (click)="onSelectList.emit(list.id)">
      <span class="list-name">{{list.name == null ? 'Fans' : list.name}}</span> <span class="count">{{list.users}}</span>
    </li>
  </ul>
  <ul>
    <li class="subscriber" *ngFor="let user of subscribers" (click)="onSelect.emit(user)">
      <app-profile-avatar [url]="getAvatar(user.images)" size="chat"></app-profile-avatar>
      <div class="spacer-w8"></div>
      <span class="action-text">{{user.nickname}}</span>
    </li>
  </ul>
</ng-container>
