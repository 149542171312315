import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../uikit/profile/profile-avatar/profile-avatar.component";
import {UserImageModelData, UserShortModelData} from "@prestinly/core-lib/lib/types/users";
import {EUserImageType} from "@prestinly/core-lib/lib/enums/user-image-type.enum";
import {
  IMessagesListUsersResponse
} from "@prestinly/core-lib/lib/types/messages/users/list.users.messages.response.interface";
import {
  IMessagesShortUsersResponse
} from "@prestinly/core-lib/lib/types/messages/users/short.users.messages.response.interface";

@Component({
  selector: 'app-subscriber-list',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
  ],
  templateUrl: './subscriber-list.component.html',
  styleUrl: './subscriber-list.component.scss'
})
export class SubscriberListComponent {
  @Input() subscribers: UserShortModelData[] = [];
  @Input() lists: IMessagesListUsersResponse | null = null;
  @Output() onSelect = new EventEmitter<UserShortModelData>();
  @Output() onSelectList = new EventEmitter<string>();
  getAvatar(images: UserImageModelData[]): string | null {
    let avatar = images.filter(image => image.imageType == EUserImageType.AVATAR);
    return avatar.length == 0 ? null : avatar[0].url;
  }
}
